//import { Image, decode } from "imagescript"
import { MyImage as Image, decode } from "./image"

type CharCodeMap = { [str: string]: number }
interface CharCodes extends Array<number> {
  [index: number]: number
}

interface RendererOutputOptions {
  baseWidth: number
  baseHeight: number
  resizeMultiply: number
  useTransparentBg: boolean
  bgColor: string
  text: string
}

interface RendererBitmapfontOptions {
  charWidth: number
  charXSp: number
  charHeight: number
  charYSp: number
  imgWidth: number
  offsetX: number
  offsetY: number
}

interface RendererRenderOptions {
  lineHeight: number
  charWidth: number
}

export interface RendererOptions {
  output: Partial<RendererOutputOptions>
  bitmapfont: Partial<RendererBitmapfontOptions>
  render: Partial<RendererRenderOptions>
}

export class Renderer {
  outputOp: RendererOutputOptions = {
    baseWidth: 200,
    baseHeight: 200,
    resizeMultiply: 2,
    useTransparentBg: true,
    bgColor: "#000",
    text: "0123あいうえお",
  }
  bitmapfontOp: RendererBitmapfontOptions = {
    charWidth: 5,
    charXSp: 1,
    charHeight: 5,
    charYSp: 1,
    imgWidth: 60,
    offsetX: 0,
    offsetY: 0,
  }
  renderOp: RendererRenderOptions = {
    lineHeight: 6,
    charWidth: 6,
  }
  /*
  OUTPUT_BASE_WIDTH = 200
  OUTPUT_BASE_HEIGHT = 200
  OUTPUT_RESIZE_MULTIPLY = 2
  OUTPUT_TEXT = ""
  */
  /*
  BITMAP_CHAR_WIDTH = 5
  BITMAP_CHAR_X_SP = 1
  BITMAP_CHAR_HEIGHT = 5
  BITMAP_CHAR_Y_SP = 1
  BITMAP_IMG_W = 60
  */
  /*
  RENDER_LINE_HEIGHT = 6
  RENDER_CHAR_WIDTH = 6
  */

  BITMAP_CODE_MAP: CharCodeMap = {}
  BITMAP_IMG: Image | null = null
  test_imgElm: HTMLImageElement

  constructor(imgElm: HTMLImageElement) {
    this.BITMAP_CODE_MAP = {}
    //decode(this.imageElementToUint8Array(imgElm)).then((img) => (this.BITMAP_IMG = img as Image))
    decode(imgElm).then((img) => (this.BITMAP_IMG = img))
  }

  imageElementToUint8Array(image: HTMLImageElement) {
    const canvas = document.createElement("canvas")!
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext("2d")
    ctx?.drawImage(image, 0, 0)
    return new Uint8Array(ctx!.getImageData(0, 0, image.width, image.height).data.buffer)
  }

  Uint8ArrayToImageElement(imgElm: HTMLImageElement, imgArray: Uint8Array) {
    imgElm.src = URL.createObjectURL(new Blob([imgArray.buffer], { type: "image/png" }))
  }

  async setBitmapfontImage(imgElm: HTMLImageElement) {
    // this.BITMAP_IMG = (await decode(this.imageElementToUint8Array(imgElm))) as Image
    this.test_imgElm = imgElm
    this.BITMAP_IMG = await decode(imgElm)
  }

  setCodemap(codesString: string) {
    this.BITMAP_CODE_MAP = this.stringToCharCodeMap(codesString)
  }

  setOptions(op: RendererOptions) {
    this.outputOp = { ...this.outputOp, ...op.output }
    this.bitmapfontOp = { ...this.bitmapfontOp, ...op.bitmapfont }
    this.renderOp = { ...this.renderOp, ...op.render }
  }

  getCharWSP() {
    return this.bitmapfontOp.charWidth + this.bitmapfontOp.charXSp
  }

  getCharHSP() {
    return this.bitmapfontOp.charHeight + this.bitmapfontOp.charYSp
  }

  getCharXCnt() {
    return Math.floor(this.bitmapfontOp.imgWidth / this.getCharWSP())
  }

  generateImgElm(text: string) {
    return this.generateImgElmWith(this.outputOp.baseWidth, this.outputOp.baseHeight, text)
  }

  async generateImgElmWith(width: number, height: number, text: string): Promise<HTMLImageElement> {
    //return this.test_imgElm
    if (this.BITMAP_IMG == null) {
      throw "bitmap img none"
    }
    /*
    return this.BITMAP_IMG.clone().then((img) => {
      return img.encode()
    })
    /*
    return this.BITMAP_IMG.resize(
      this.BITMAP_IMG.width * this.outputOp.resizeMultiply,
      this.BITMAP_IMG.height * this.outputOp.resizeMultiply,
      Image.RESIZE_NEAREST_NEIGHBOR
    ).then((img) => {
      return img.encode().then((imgElm) => {
        return imgElm
      })
    })
    */
    //console.log(text)
    const outputImg = await this.renderText(new Image(width, height), text)
    return outputImg
      .resize(
        outputImg.width * this.outputOp.resizeMultiply,
        outputImg.height * this.outputOp.resizeMultiply,
        Image.RESIZE_NEAREST_NEIGHBOR
      )
      .then((img) => {
        return img.encode().then((imgElm) => {
          return imgElm
        })
      })
  }

  async renderText(img: Image, text: string) {
    if (!this.outputOp.useTransparentBg) {
      img.drawFillCanvas(this.outputOp.bgColor)
    }
    const charCodesLines = this.stringToMultiLineCharCodes(text)
    //console.log(bitmapCodesLines)
    const bitmapfontImgElm = await this.BITMAP_IMG!.getNowImage()

    for (let ln = 0; ln < charCodesLines.length; ln++) {
      const charCodes = charCodesLines[ln]
      for (let i = 0; i < charCodes.length; i++) {
        //const strImg = await this.getCharImgFromCharCode(charCodes[i])
        //if (!strImg) throw img
        //await img.composite(strImg, i * this.renderOp.charWidth, ln * this.renderOp.lineHeight)
        const [sx, sy, sw, sh] = this.getCharPos(charCodes[i])
        img.drawImage(
          bitmapfontImgElm,
          sx,
          sy,
          sw,
          sh,
          i * this.renderOp.charWidth,
          ln * this.renderOp.lineHeight,
          sw,
          sh
        )
      }
    }
    return img
  }

  getCharPos(code: number): [number, number, number, number] {
    if (!this.BITMAP_IMG) throw "failed bitmap img"
    const x = code % this.getCharXCnt()
    const y = Math.floor(code / this.getCharXCnt())
    return [
      x * this.getCharWSP() + this.bitmapfontOp.offsetX,
      y * this.getCharHSP() + this.bitmapfontOp.offsetY,
      this.bitmapfontOp.charWidth,
      this.bitmapfontOp.charHeight,
    ]
  }

  stringToCharCodeMap(bitmapText: string) {
    const strings = bitmapText.split("\n").flatMap((n) => n.trim().split(""))
    const codeMap: CharCodeMap = {}
    for (let i = 0; i < strings.length; i++) {
      if (this.isHankakuChar(strings[i])) {
        const zenkaku = this.hankakuToZenkaku(strings[i])
        codeMap[zenkaku] = i
      }
      codeMap[strings[i]] = i
    }
    return codeMap
  }

  async getCharImgFromCharCode(code: number) {
    if (!this.BITMAP_IMG) throw "failed bitmap img"
    const [x, y, w, h] = this.getCharPos(code)
    //const x = code % this.getCharXCnt()
    //const y = Math.floor(code / this.getCharXCnt())
    return (await this.BITMAP_IMG.clone()).crop(x, y, w, h)
  }

  stringToCharCodes(strs: string) {
    const strings = strs.split("")
    const bitmapCodes: CharCodes = []
    for (let i = 0; i < strings.length; i++) {
      bitmapCodes[i] = this.BITMAP_CODE_MAP[strings[i]] ?? -1
    }

    return bitmapCodes
  }

  stringToMultiLineCharCodes(strs: string) {
    const lines = strs.split("\n")
    const output: CharCodes[] = []
    for (let i = 0; i < lines.length; i++) {
      output[i] = this.stringToCharCodes(lines[i])
    }
    return output
  }

  isHankakuChar(str: string) {
    return str.match(/[A-Za-z0-9]/g) != null
  }

  hankakuToZenkaku(str: string) {
    return str.replace(/[A-Za-z0-9]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + 0xfee0)
    })
  }
}

/*
    this.OUTPUT_BASE_WIDTH = op.output.baseWidth ?? this.OUTPUT_BASE_WIDTH
    this.OUTPUT_BASE_HEIGHT = op.output.baseHeight ?? this.OUTPUT_BASE_HEIGHT

    this.BITMAP_CHAR_WIDTH = op.bitmapfont.charWidth ?? this.BITMAP_CHAR_WIDTH
    this.BITMAP_CHAR_X_SP = op.bitmapfont.charXSp ?? this.BITMAP_CHAR_X_SP
    this.BITMAP_CHAR_HEIGHT = op.bitmapfont.charHeight ?? this.BITMAP_CHAR_HEIGHT
    this.BITMAP_CHAR_Y_SP = op.bitmapfont.charYSp ?? this.BITMAP_CHAR_Y_SP
    */

/*
const INPUT_BITMAP_IMGPATH = "bitmapfont5a.png"
const INPUT_BITMAP_MAPPINGTXT = "bitmap.txt" //このテキスト内のスペースや改行は無視されるはずなので、ビットマップ画像に穴開きの部分がある場合は、テキスト側はそこを適当な使わない文字で埋める必要があると思う
const OUTPUT_FILEPATH = "out.png"
const OUTPUT_BASE_WIDTH = 200
const OUTPUT_BASE_HEIGHT = 200
const OUTPUT_RESIZE_MULTIPLY = 2
const OUTPUT_TEXT = `
　　　　゛
とりあえす
　　　　　
てきとうにつくってみた

　　　゛
いままてふぉんとつくったことないし
゛　　　　　　　゛　　　　　　　゛
とっとえもほとんとしたことないけと
　　　　゜　　　　　　　　　　　゛
5かけ5ひくせるふぉんとちゃれんしってのやってたから
　　゛　゛　゛　゛
ひらかなたけたけとつくってみた
　　　　　　゛゛゛　　　　　　　゜　゛
このふぉんとてかそうせいせいするふろくらむつくってみたら
　　　　　゛　　　　　　　　　　　　　　　　　　　　゛
いろいろなふんしょうをにゅうりょくしてせいせいするのかたのしかった
　゛　　　　゛
のてよかったてす

　　　　　　　　　　゛
ゆにてぃ１うぃーくにけーむつくるのまにあわせるの
　　　　　　　　　
あきらめたよ～（そのうちかんせいさせたいね…）　
`

/*
const BITMAP_IMG = (await decode(await Deno.readFile(INPUT_BITMAP_IMGPATH))) as Image
const BITMAP_CHAR_WIDTH = 5
const BITMAP_CHAR_X_SP = 1
const BITMAP_CHAR_HEIGHT = 5
const BITMAP_CHAR_Y_SP = 1
const BITMAP_IMG_W = 60
const RENDER_LINE_HEIGHT = 6
const RENDER_CHAR_WIDTH = 6

const BITMAP_CHAR_WIDTH_INCLUDE_SP = BITMAP_CHAR_WIDTH + BITMAP_CHAR_X_SP
const BITMAP_CHAR_HEIGHT_INCLUDE_SP = BITMAP_CHAR_HEIGHT + BITMAP_CHAR_Y_SP
const BITMAP_CHAR_X_COUNT = Math.floor(BITMAP_IMG_W / BITMAP_CHAR_WIDTH_INCLUDE_SP)
const BITMAP_CODE_MAP: CharCodeMap = stringToCharCodeMap(await Deno.readTextFile(INPUT_BITMAP_MAPPINGTXT))

//const bitmaps = toMultiLineBitmapCodes(outputText)
//console.log(bitmaps)

///const outputImg = renderText(new Image(200, 200), OUTPUT_TEXT)
await Deno.writeFile(OUTPUT_FILEPATH, await generateImgFile(OUTPUT_BASE_WIDTH, OUTPUT_BASE_HEIGHT, OUTPUT_TEXT))
console.log("finished!")
*/
