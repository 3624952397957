export async function decode(imgElm: HTMLImageElement) {
  const canvas = document.createElement("canvas")!
  canvas.width = imgElm.naturalWidth
  canvas.height = imgElm.naturalHeight
  const ctx = canvas.getContext("2d")
  ctx?.drawImage(imgElm, 0, 0)
  const myImg = new MyImage(canvas.width, canvas.height)
  return myImg._setCanvas(canvas)
}

export class MyImage {
  private _canvas: HTMLCanvasElement
  private _ctx: CanvasRenderingContext2D
  static RESIZE_NEAREST_NEIGHBOR = ""
  constructor(width: number, height: number) {
    this._canvas = document.createElement("canvas")
    this._canvas.width = width
    this._canvas.height = height

    this._ctx = this._canvas.getContext("2d")!
  }
  get ctx(): CanvasRenderingContext2D {
    return this._ctx
  }
  get width(): number {
    return this._canvas.width
  }
  get height(): number {
    return this._canvas.height
  }
  makeCanvas(width: number, height: number): [HTMLCanvasElement, CanvasRenderingContext2D] {
    const canvas = document.createElement("canvas")
    //console.log("makeCanvas ", canvas)
    //document.body.appendChild(canvas)
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext("2d")
    if (!ctx) {
      throw "failed get ctx"
    }
    return [canvas, ctx]
  }
  async clone() {
    const myImage = new MyImage(this._canvas.width, this._canvas.height)
    await myImage.composite(this, 0, 0)
    return myImage
  }
  crop(x: number, y: number, width: number, height: number) {
    const [outCanvas, outCtx] = this.makeCanvas(width, height)
    return this.getNowImage().then((img) => {
      outCtx.drawImage(img, x, y, width, height, 0, 0, width, height)
      return this._setCanvas(outCanvas)
    })
  }
  composite(source: MyImage, x?: number | undefined, y?: number | undefined) {
    return source.getNowImage().then((img) => {
      return this.compositeFromImageElm(img, x, y)
    })
  }
  compositeFromImageElm(imgElm: HTMLImageElement, x?: number | undefined, y?: number | undefined) {
    this.ctx.drawImage(imgElm, x ?? 0, y ?? 0)
    return this
  }
  drawImage(
    imgElm: HTMLImageElement,
    sx: number,
    sy: number,
    sw: number,
    sh: number,
    dx: number,
    dy: number,
    dw: number,
    dh: number
  ) {
    this.ctx.drawImage(imgElm, sx, sy, sw, sh, dx, dy, dw, dh)
    return this
  }
  drawFillCanvas(fillStyle: string | CanvasGradient | CanvasPattern) {
    this.ctx.fillStyle = fillStyle
    this.ctx.fillRect(0, 0, this.width, this.height)
    return this
  }
  async resize(width: number, height: number, mode?: string | undefined): Promise<MyImage> {
    const [outCanvas, outCtx] = this.makeCanvas(width, height)
    outCtx.imageSmoothingEnabled = false
    return this.getNowImage().then((img) => {
      outCtx.drawImage(img, 0, 0, this._canvas.width, this._canvas.height, 0, 0, width, height)
      return this._setCanvas(outCanvas)
    })
  }
  encode() {
    return this.getNowImage()
  }

  _setCanvas(canvas: HTMLCanvasElement) {
    this._canvas = canvas
    this._ctx = this._canvas.getContext("2d")!
    return this
  }

  getNowImage() {
    return this.getImagefromCanvas(this._canvas)
  }

  getImagefromCanvas(canvas: HTMLCanvasElement): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => resolve(image)
      image.onerror = (e) => reject(e)
      image.src = canvas.toDataURL()
    })
  }
  /*
  imageElementToUint8Array(image: HTMLImageElement) {
    const canvas = document.createElement("canvas")!
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext("2d")
    ctx?.drawImage(image, 0, 0)
    return new Uint8Array(ctx!.getImageData(0, 0, image.width, image.height).data.buffer)
  }

  Uint8ArrayToImageElement(imgElm: HTMLImageElement, imgArray: Uint8Array) {
    imgElm.src = URL.createObjectURL(new Blob([imgArray.buffer], { type: "image/png" }))
  }
  */
}
