import { Renderer, RendererOptions } from "./renderer"
import "./style.css"

console.log("index")

const bitmapfontImgElm = document.querySelector<HTMLImageElement>("#bitmapImage")!
const bitmapImageInputElm = document.querySelector<HTMLInputElement>("#bitmapImageInput")!

const bitmapfontImgWidthInputElm = document.querySelector<HTMLInputElement>("#bitmapfontImgWidth")!
const bitmapfontImgOffsetXInputElm = document.querySelector<HTMLInputElement>("#bitmapfontImgOffsetX")!
const bitmapfontImgOffsetYInputElm = document.querySelector<HTMLInputElement>("#bitmapfontImgOffsetY")!
const charWidthInputElm = document.querySelector<HTMLInputElement>("#charWidth")!
const charXSpInputElm = document.querySelector<HTMLInputElement>("#charXSp")!
const charHeightInputElm = document.querySelector<HTMLInputElement>("#charHeight")!
const charYSpInputElm = document.querySelector<HTMLInputElement>("#charYSp")!

const renderLineHeightInputElm = document.querySelector<HTMLInputElement>("#renderLineHeight")!
const renderCharWidthInputElm = document.querySelector<HTMLInputElement>("#renderCharWidth")!

const outBaseWidthInputElm = document.querySelector<HTMLInputElement>("#outBaseWidth")!
const outBaseHeightInputElm = document.querySelector<HTMLInputElement>("#outBaseHeight")!
const outResizeMultiplyInputElm = document.querySelector<HTMLInputElement>("#outResizeMultiply")!
const outUseTransparentBgInputElm = document.querySelector<HTMLInputElement>("#outUseTransparentBg")!
const outBgColorInputElm = document.querySelector<HTMLInputElement>("#outBgColor")!

const mappingTextareaElm = document.querySelector<HTMLTextAreaElement>("#mappingTextarea")!

const inputTextAreaElm = document.querySelector<HTMLTextAreaElement>("#inputTextAreaElm")!
const renderBtnElm = document.querySelector<HTMLButtonElement>("#renderBtn")!
const outputImgElmContainer = document.querySelector<HTMLImageElement>("#outputImageContainer")!

const renderer = new Renderer(bitmapfontImgElm)
renderer.setBitmapfontImage(bitmapfontImgElm)

function collectOptions() {
  const op: RendererOptions = {
    output: {},
    bitmapfont: {},
    render: {},
  }

  validIntElm(bitmapfontImgWidthInputElm, (num) => (op.bitmapfont.imgWidth = num))
  validIntElm(bitmapfontImgOffsetXInputElm, (num) => (op.bitmapfont.offsetX = num))
  validIntElm(bitmapfontImgOffsetYInputElm, (num) => (op.bitmapfont.offsetY = num))
  validIntElm(charWidthInputElm, (num) => (op.bitmapfont.charWidth = num))
  validIntElm(charXSpInputElm, (num) => (op.bitmapfont.charXSp = num))
  validIntElm(charHeightInputElm, (num) => (op.bitmapfont.charHeight = num))
  validIntElm(charYSpInputElm, (num) => (op.bitmapfont.charYSp = num))

  validIntElm(renderLineHeightInputElm, (num) => (op.render.lineHeight = num))
  validIntElm(renderCharWidthInputElm, (num) => (op.render.charWidth = num))

  validIntElm(outBaseWidthInputElm, (num) => (op.output.baseWidth = num))
  validIntElm(outBaseHeightInputElm, (num) => (op.output.baseHeight = num))
  validIntElm(outResizeMultiplyInputElm, (num) => (op.output.resizeMultiply = num))
  op.output.useTransparentBg = outUseTransparentBgInputElm.checked
  op.output.bgColor = outBgColorInputElm.value

  return op
}

function validIntElm(elm: HTMLInputElement, callback: (num) => void) {
  const int = parseInt(elm.value, 10)
  if (isNaN(int)) {
    elm.setCustomValidity("整数を入力してください")
    elm.reportValidity()
    return false
  }
  elm.setCustomValidity("")
  callback(int)
  elm.value = int.toString()
  return true
}

//let outputImgElm = document.querySelector<HTMLImageElement>("#outputImage")!
//console.log(bitmapfontImgElm)

bitmapImageInputElm.addEventListener("change", function (e) {
  const file = (e.currentTarget as HTMLInputElement).files?.[0] //複数ファイルはfiles配列をループで回す
  const reader = new FileReader()
  reader.addEventListener(
    "load",
    async function () {
      new Promise((resolve: (elm: HTMLImageElement) => void, reject) => {
        bitmapfontImgElm.onload = () => resolve(bitmapfontImgElm)
        bitmapfontImgElm.onerror = (e) => reject(e)
        //bitmapfontImgElm.src = canvas.toDataURL()
        bitmapfontImgElm.src = reader.result as string
      }).then((elm) => {
        notify("ビットマップフォント画像ロード完了")
        renderer.setBitmapfontImage(elm)
      })
    },
    false
  )

  if (file) {
    reader.readAsDataURL(file)
  }
})

renderBtnElm.addEventListener("click", async () => {
  const options = collectOptions()
  //console.log("render")
  const codeMap = mappingTextareaElm.value
  renderer.setCodemap(codeMap)
  renderer.setOptions(options)

  renderer.generateImgElm(inputTextAreaElm.value).then((elm) => {
    outputImgElmContainer.innerHTML = ""
    outputImgElmContainer.appendChild(elm)
    notify("出力完了")
  })

  /*
  renderer.generateImgElm().then((imgArray) => {
    renderer.Uint8ArrayToImageElement(imgElm, imgArray)
  })
  */
})
/*
document.querySelector<HTMLDivElement>("#app")!.innerHTML = `
  <div>
   
  </div>
`
*/

let notify_timeout_id: number | null = null
const NOTIFY_DOM_ID = "notify"
const NOTIFY_DOM = document.querySelector("#notifyContainer")!
function notify(text) {
  if (notify_timeout_id) {
    clearTimeout(notify_timeout_id)
  }
  var div = document.getElementById(NOTIFY_DOM_ID)
  if (!div) {
    div = document.createElement("div")
    div.setAttribute("style", "position: fixed; top: 0px; background-color: orange;")
    div.id = NOTIFY_DOM_ID
    NOTIFY_DOM.appendChild(div)
  }
  div.textContent = text
  div.setAttribute("style", "position: fixed; top: 0px; background-color: orange;")
  notify_timeout_id = setTimeout(() => {
    div?.setAttribute("style", "position: fixed; top: 0px; background-color: orange; display:none;")
  }, 3000)
}
